import React, { useState } from "react";
import { Modal, ModalBuilder } from "@redriver/cinnamon";
import { EmailQuoteForm } from "components/forms";
import "react-multi-email/dist/style.css";
import { emailQuote, getQuoteEmailTemplate } from "./actions";

const EmailQuoteModal = ({ visible, currentQuote, closeModal }) => {
  const [availableAttachments, setAvailableAttachments] = useState([]);
  return (
    <ModalBuilder
      withForm
      loadAction={getQuoteEmailTemplate}
      onLoaded={(data) =>
        setAvailableAttachments(
          data?.availableAttachments?.map((a) => ({
            key: a.id,
            text: a.name,
            value: a.id,
          })) ?? [],
        )
      }
      loadParams={currentQuote.id}
      loadTransform={(data) => ({
        ...data,
        toAddresses: [data.recipient],
        attachmentIds:
          data.availableAttachments
            ?.filter((a) => a.shouldAutoAttach)
            ?.map((a) => a.id) ?? [],
      })}
      submitAction={emailQuote}
      submitParams={currentQuote.id}
      onSubmitted={closeModal}
      visible={visible}
      onCancel={closeModal}
      renderModal={(modalProps, formProps) => {
        return (
          <Modal {...modalProps} header="Send Quote Email" submitLabel="Send">
            <EmailQuoteForm
              formProps={formProps}
              availableAttachments={availableAttachments}
            />
          </Modal>
        );
      }}
    />
  );
};

export default EmailQuoteModal;

import React, { useState } from "react";
import { LinePanel } from "components/containers";
import ResidentialGreen from "assets/icons/residential-green.svg";
import CommercialBlue from "assets/icons/commercial-blue.svg";
import TreeIcon from "assets/icons/tree-silhouette.svg";
import CheckIcon from "assets/icons/check.svg";
import {
  ContractJobType,
  CustomerTypes,
  JobStatus,
  JobTpoStatus,
} from "constants/enums";
import { Format } from "@redriver/cinnamon";
import { Popup, Menu, Image } from "semantic-ui-react";
import { useSegmentContextMenu } from "modules/helpers";
import { useHistory } from "react-router-dom";
import { JobRoutes } from "constants/routes";
import SpreadJob from "./SpreadJob";
import { JobTabKeys } from "constants/index";
import CompleteJobSheet from "./CompleteJobSheet";
import RepeatJob from "./RepeatJob";
import { useDispatch } from "react-redux";
import { generateJobSheet } from "./actions";
import { PermissionCheck, MultiPermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";
import classNames from "classnames";
import ContractRed from "assets/icons/contract-light-red.svg";
import ContractBlack from "assets/icons/contract-black.svg";
import CheckIconGreen from "assets/icons/check-green.svg";

const JobCard = ({
  job,
  innerRef,
  containerRef,
  noSpread = false,
  ...props
}) => {
  const items = job.items || [];
  const assigned = job.assigned || [];

  const [onContextMenu, open, onClose, contextRef] = useSegmentContextMenu(
    job.id,
    containerRef,
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const [showSpreadModal, setShowSpreadModal] = useState(false);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [showRepeatJobModal, setShowRepeatJobModal] = useState(false);

  return (
    <div className="job-card-container" ref={innerRef} {...props}>
      <Popup
        flowing
        hoverable
        position="bottom left"
        className="job-popup"
        mouseEnterDelay={1000}
        popperModifiers={[
          {
            options: {
              preventOverflow: {
                boundariesElement: "offsetParent",
              },
            },
          },
        ]}
        on="hover"
        trigger={
          <LinePanel
            id={job.id}
            className={classNames(
              "job-card",
              job.contractId ? "contract" : null,
            )}
            highlight={
              job.contractJobType == ContractJobType.Burials
                ? "burials-black"
                : job.contractId
                  ? "contract-red"
                  : job.customerType == CustomerTypes.Residential
                    ? "residential-green"
                    : "commercial-blue"
            }
            tint={
              job.contractJobType == ContractJobType.Burials
                ? "faded-blue"
                : job.contractId
                  ? "faded-red"
                  : job.customerType == CustomerTypes.Residential
                    ? "faded-green"
                    : "faded-blue"
            }
            backgroundUrl={
              job.contractJobType == ContractJobType.Burials
                ? ContractBlack
                : job.contractId
                  ? ContractRed
                  : job.customerType == CustomerTypes.Residential
                    ? ResidentialGreen
                    : CommercialBlue
            }
            onContextMenu={onContextMenu}
          >
            <header>
              {job.jobStatusText == JobStatus.Completed ||
              job.jobStatusText == JobStatus.Invoiced ? (
                <span
                  className={classNames(
                    "job-status",
                    job.jobStatusText == JobStatus.Invoiced ? "invoiced" : null,
                  )}
                >
                  {job.jobStatusText}
                </span>
              ) : (
                items.some((i) => i.isComplete) && (
                  <span className={classNames("job-status", "part-complete")}>
                    Part Complete
                  </span>
                )
              )}
              <h5>{job.jobRef}</h5>
              <span>
                <Format.Number value={job.price} format="GBP" />
              </span>
              {job.jobStatusText == JobStatus.Completed ||
              job.jobStatusText == JobStatus.Invoiced ? (
                <Image src={CheckIcon} />
              ) : job.jobTpoStatus == JobTpoStatus.Check ||
                job.jobTpoStatus == JobTpoStatus.Application ? (
                <Image src={TreeIcon} />
              ) : items.some((i) => i.isComplete) ? (
                <Image src={CheckIconGreen} />
              ) : null}
            </header>
            <footer>
              <span>{job.customer}</span>
              {job.siteAddress && <span>{job.siteAddress.townOrCity}</span>}
              {job.contractPlots && (
                <span className="contract-plots">{job.contractPlots}</span>
              )}
            </footer>
          </LinePanel>
        }
      >
        <section className="scope-of-work">
          <header>
            {(job.jobTpoStatus == JobTpoStatus.Check ||
              job.jobTpoStatus == JobTpoStatus.Application) && (
              <span style={{ marginBottom: "5px" }}>
                {job.jobTpoStatusText}
              </span>
            )}
            <span className="title">Scope of work</span>

            <span>
              <Format.Number value={job.price} format="GBP" />
            </span>
          </header>
          {items.map((x) => (
            <div className="job-item" key={x.id}>
              <div className="details">
                {x.quantity} x {x.productName}{" "}
                {x.isComplete && <Image src={CheckIconGreen} />}
              </div>
              <div className="description">{x.description}</div>
            </div>
          ))}
          {items.length == 0 && <span className="no-items">No items</span>}
          {assigned.length != 0 && (
            <footer>Assigned: {assigned.join(", ")}</footer>
          )}
        </section>
      </Popup>

      <Popup
        basic
        className="job-context-menu"
        context={contextRef}
        onClose={onClose}
        open={open}
        key={contextRef.current?.key}
      >
        <Menu vertical compact>
          <PermissionCheck target={Targets.Job} action={Actions.View}>
            <Menu.Item
              className="context-menu-item"
              onClick={() =>
                history.push(JobRoutes.Job(job.jobId), {
                  tabName: JobTabKeys.Schedule,
                })
              }
            >
              View Job
            </Menu.Item>
          </PermissionCheck>
          {!noSpread &&
            job.jobStatusText != JobStatus.Completed &&
            job.jobStatusText != JobStatus.Invoiced && (
              <React.Fragment>
                {!job.isRepeatJob && (
                  <PermissionCheck
                    target={Targets.JobScheduling}
                    action={Actions.Edit}
                  >
                    <Menu.Item
                      className="context-menu-item"
                      onClick={() => {
                        setShowSpreadModal(true);
                        onClose();
                      }}
                    >
                      Spread Job
                    </Menu.Item>
                  </PermissionCheck>
                )}
                {job.jobStatusText != JobStatus.Pending && (
                  <PermissionCheck target={Targets.Job} action={Actions.Edit}>
                    <Menu.Item
                      className="context-menu-item"
                      onClick={() => {
                        setShowCompletionModal(true);
                        onClose();
                      }}
                    >
                      Complete Job Sheet
                    </Menu.Item>
                  </PermissionCheck>
                )}
                <MultiPermissionCheck
                  checks={[
                    { target: Targets.Job, action: Actions.Edit },
                    { target: Targets.JobScheduling, action: Actions.Edit },
                  ]}
                >
                  <Menu.Item
                    className="context-menu-item"
                    onClick={() => {
                      dispatch(
                        generateJobSheet({
                          jobId: job.jobId,
                          jobSheetId: job.id,
                        }),
                      );
                      onClose();
                    }}
                  >
                    Generate Job Sheet
                  </Menu.Item>
                </MultiPermissionCheck>
                {!job.contractId && (
                  <PermissionCheck
                    target={Targets.JobScheduling}
                    action={Actions.Edit}
                  >
                    <Menu.Item
                      className="context-menu-item"
                      onClick={() => {
                        setShowRepeatJobModal(true);
                        onClose();
                      }}
                    >
                      Repeat Job
                    </Menu.Item>
                  </PermissionCheck>
                )}
              </React.Fragment>
            )}
        </Menu>
      </Popup>
      <SpreadJob
        job={job}
        visible={showSpreadModal}
        onClose={() => setShowSpreadModal(false)}
      />
      <CompleteJobSheet
        job={job}
        visible={showCompletionModal}
        onClose={() => setShowCompletionModal(false)}
      />
      <RepeatJob
        jobSheet={job}
        visible={showRepeatJobModal}
        onClose={() => setShowRepeatJobModal(false)}
      />
    </div>
  );
};

export default JobCard;

import { Form } from "@redriver/cinnamon";
import React from "react";
import { Grid } from "semantic-ui-react";
import FormMultiEmailSelect from "./FormMultiEmailSelect";

const EmailQuoteForm = ({ formProps, availableAttachments }) => (
  <Form {...formProps}>
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <FormMultiEmailSelect label="To" field="toAddresses" fluid required />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2} className="email-copy-addresses">
        <Grid.Column>
          <FormMultiEmailSelect
            field="ccAddresses"
            label="Cc"
            placeholder="Input email addresses here"
          />
        </Grid.Column>
        <Grid.Column>
          <FormMultiEmailSelect
            field="bccAddresses"
            label="Bcc"
            placeholder="Input email addresses here"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Form.Input label="Subject" field="subject" fluid required />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Form.Dropdown
            field="attachmentIds"
            label="Attachments"
            fluid
            multiple
            search
            options={availableAttachments}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Form.TextArea rows={12} label="Body" field="body" fluid required />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Form>
);

export default EmailQuoteForm;

import React from "react";
import { Segment } from "semantic-ui-react";
import classNames from "classnames";
import PropTypes from "prop-types";

const LinePanel = ({
  children,
  className,
  style = {},
  highlight,
  highlightFull,
  tint,
  backgroundUrl,
  dashed,
  ...props
}) => (
  <Segment
    className={classNames(
      "line-panel",
      highlight
        ? `highlight${highlightFull ? "-full" : ""}-${highlight}`
        : null,
      tint ? `tint-${tint}` : null,
      backgroundUrl ? "has-background" : null,
      dashed ? "dashed-border" : null,
      className,
    )}
    style={{
      backgroundImage: backgroundUrl ? `url('${backgroundUrl}')` : "none",
      ...style,
    }}
    {...props}
  >
    {children}
  </Segment>
);

LinePanel.propTypes = {
  highlight: PropTypes.oneOf([
    "red",
    "orange",
    "green",
    "blue",
    "grey",
    "faded-blue",
    "faded-green",
    "residential-green",
    "commercial-blue",
    "purple",
    "contract-red",
    "burials-black",
  ]),
  highlightFull: PropTypes.bool,
  tint: PropTypes.oneOf([
    "red",
    "orange",
    "green",
    "blue",
    "grey",
    "faded-blue",
    "faded-green",
    "faded-red",
  ]),
  backgroundUrl: PropTypes.string,
};

export default LinePanel;

import { Format, useAuthClaims } from "@redriver/cinnamon";
import LocationIcon from "assets/icons/location.svg";
import ContractRed from "assets/icons/contract-light-red.svg";
import ContractOrange from "assets/icons/contract-light-orange.svg";
import ContractPurple from "assets/icons/contract-light-purple.svg";
import ContractGreen from "assets/icons/contract-light-green.svg";
import ContractDarkGreen from "assets/icons/contract-dark-green.svg";
import UserIcon from "assets/icons/user.svg";
import classNames from "classnames";
import { LinePanel } from "components/containers";
import { ContractJobType } from "constants/enums";
import React from "react";
import { Image } from "semantic-ui-react";
import { Actions, Targets } from "constants/Permissions";
import { claimExists } from "components/auth/utils";
import { ContractRoutes } from "constants/routes";
import { Link } from "react-router-dom";

const Card = ({ item, background, colour, className }) => (
  <LinePanel
    className={classNames("contract-card", className)}
    highlight={colour}
  >
    <div
      style={{
        backgroundImage: background ? `url('${background}')` : "none",
      }}
      className="contract-background"
      title={`Department: ${item.departmentName}`}
    >
      <div className="title">
        <h3 className="contract-title">{item.customerName}</h3>
        <div style={{ minHeight: "40px" }}>
          <div>{item.reference}</div>
          {item.contractNumber && <div>{item.contractNumber}</div>}
        </div>
      </div>
      <div className="contract-body">
        <p title={item.siteAddress ?? null}>
          <Image inline src={LocationIcon} />
          <span>{item.siteAddress ?? "No site address selected"}</span>
        </p>
        <p>
          <Image inline src={UserIcon} />{" "}
          <span>{item.contractManagerName}</span>
        </p>
      </div>
    </div>
    <div className="contract-footer">
      <span>{item.jobTypeDescription}</span>
      <div className="contract-date">
        <Format.Date value={item.createdUtc} format="DD MMM YYYY" />
      </div>
    </div>
  </LinePanel>
);

const ContractCard = ({ item, useJobTypeColour = false, className }) => {
  const { permissions } = useAuthClaims();

  let colour = null;
  let background = null;
  if (useJobTypeColour) {
    switch (item.jobTypeDescription) {
      case ContractJobType.Fencing:
        colour = "red";
        background = ContractRed;
        break;
      case ContractJobType.Landscaping:
        colour = "orange";
        background = ContractOrange;
        break;
      case ContractJobType.LandscapingAndFencing:
        colour = "purple";
        background = ContractPurple;
        break;
      case ContractJobType.GroundsMaintenance:
        colour = "green";
        background = ContractGreen;
        break;
      case ContractJobType.Burials:
        colour = "dark-green";
        background = ContractDarkGreen;
        break;
    }
  }

  return (
    <React.Fragment>
      {claimExists(permissions, Targets.Contract, Actions.View) ? (
        <Link to={ContractRoutes.Contract(item.id)} className="basic">
          <Card
            item={item}
            colour={colour}
            background={background}
            className={className}
          />
        </Link>
      ) : (
        <div className="inline">
          <Card
            item={item}
            colour={colour}
            background={background}
            className={className}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default ContractCard;

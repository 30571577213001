export const EnquiryStatus = {
  Open: "Open",
  InProgress: "In Progress",
  Quoted: "Quoted",
  Converted: "Converted",
  Closed: "Closed",
  Lost: "Lost",
};

export const CustomerTypes = {
  Unknown: "Unknown",
  Residential: "Residential",
  Commercial: "Commercial",
  Contract: "Contract",
};

export const AddressTypes = {
  Site: "Site",
  Billing: "Billing",
  Home: "Home",
  Main: "Main",
};

export const JobStatus = {
  ToBeScheduled: "To Be Scheduled",
  Scheduled: "Scheduled",
  Completed: "Completed",
  Invoiced: "Invoiced",
  Pending: "Pending",
  Cancelled: "Cancelled",
  Draft: "Draft",
};

export const ResourceSchedulingState = {
  Available: "Available",
  Absent: "Sick",
  OnHoliday: "On Holiday",
  OtherEvent: "Absent (Other)",
  MaternityPaternity: "Maternity / Paternity",
  Unavailable: "Unavailable / PT",
};

export const ShortenedResourceSchedulingState = {
  Available: "Available",
  Absent: "Sick",
  OnHoliday: "Holiday",
  OtherEvent: "Absent",
  MaternityPaternity: "Maternity / Paternity",
  Unavailable: "Unavailable",
};

export const PartOfDay = {
  Am: "Am",
  Pm: "Pm",
  FullDay: "FullDay",
};

export const InvoiceType = {
  Full: "Full Invoice",
  Partial: "Partial Invoice",
};

export const InvoiceTypeFields = {
  Full: "Full",
  Partial: "Partial",
};

export const TemplateDocumentTypes = {
  RiskAssessment: "RiskAssessment",
  TermsAndConditions: "TermsAndConditions",
};

export const CancelType = {
  Single: 0,
  Series: 1,
};

export const JobTpoStatus = {
  None: "None",
  Check: "Check",
  Application: "Application",
  Accepted: "Accepted",
  Amended: "Amended",
  Declined: "Decline",
  Other: "Other",
};

export const RepeatJobFrequency = {
  Daily: "Daily",
  Weekly: "Weekly",
  Monthly: "Monthly",
};

export const getEnumAsOptionsList = (enumObj) => {
  return Object.keys(enumObj).map((k) => ({ value: k, text: enumObj[k] }));
};

export const ContractContactPointTypeFields = {
  Email: "Email",
  PhoneNumber: "PhoneNumber",
};

export const ContractPricingType = {
  PlotWorksFencing: "Plot Works Fencing",
  PlotWorksLandscaping: "Plot Works Landscaping",
  PosWorksFencing: "POS Works Fencing",
  PosWorksLandscaping: "POS Works Landscaping",
  InfrastructureWorks: "Infrastructure Works",
  MaintenanceWorks: "Maintenance Works",
  AdditionalWorks: "Additional Works",
};

export const ContractPricingTypeFields = {
  PlotWorksFencing: "PlotWorksFencing",
  PlotWorksLandscaping: "PlotWorksLandscaping",
  PosWorksFencing: "PosWorksFencing",
  PosWorksLandscaping: "PosWorksLandscaping",
  InfrastructureWorks: "InfrastructureWorks",
  MaintenanceWorks: "MaintenanceWorks",
  AdditionalWorks: "AdditionalWorks",
};

export const ContractStatus = {
  New: "New",
  Quoted: "Quoted",
  InProgress: "In Progress",
  OnHold: "On Hold",
  Completed: "Completed",
  Cancelled: "Lost",
};

export const ContractJobType = {
  Fencing: "Fencing",
  Landscaping: "Landscaping",
  LandscapingAndFencing: "Landscaping & Fencing",
  GroundsMaintenance: "Grounds Maintenance",
  Burials: "Burials",
};

export const ContractPricingInvoicingType = {
  PricePerPlot: "Price per Plot",
  AveragePrice: "Average Price",
  PercentageOfPrice: "% of Price",
};

export const ContractPricingInvoicingTypeFields = {
  PricePerPlot: "PricePerPlot",
  AveragePrice: "AveragePrice",
  PercentageOfPrice: "PercentageOfPrice",
};

export const ContractDocumentCategory = {
  Contract: "Contract",
  Sales: "Sales",
};

export const ContractDocumentType = {
  General: "General",
  RiskAssessment: "RiskAssessment",
  SiteDrawings: "SiteDrawings",
  PlantList: "PlantList",
  MaterialsList: "MaterialsList",
  Contract: "Contract",
  WorkOrder: "WorkOrder",
  Rams: "Rams",
  Other: "Other",
  SitePhotos: "SitePhotos",
  Finance: "Finance",
};

export const MobileUserStatus = {
  Active: "Active",
  Inactive: "Inactive",
};

export const JobFulfilmentOfWorksItemType = {
  RiskAssessmentCompleted: "RiskAssessmentCompleted",
  PhotographsBefore: "PhotographsBefore",
  PhotographsAfter: "PhotographsAfter",
  WorksCompleted: "WorksCompleted",
};

export const EnquiryDocumentType = {
  General: "General",
  Estimate: "Estimate",
  Quote: "Quote",
  RiskAssessment: "RiskAssessment",
  Invoice: "Invoice",
  JobSheet: "JobSheet",
};

export const JobDocumentType = {
  General: "General",
  JobSheet: "JobSheet",
  SitePhotos: "SitePhotos",
  RiskAssessment: "RiskAssessment",
  SiteDrawings: "SiteDrawings",
  PlantList: "PlantList",
  MaterialsList: "MaterialsList",
  Contract: "Contract",
  WorkOrder: "WorkOrder",
  Rams: "Rams",
  Other: "Other",
};
